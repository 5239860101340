class ProgressHandler {
    constructor() {
      this.showMessage = null;
      this.updateMessage = null;
      this.hideMessage = null;
    }
  
    setHandlers({ showMessage, updateMessage, hideMessage }) {
      this.showMessage = showMessage;
      this.updateMessage = updateMessage;
      this.hideMessage = hideMessage;
    }
  
    show(msg) {
      if (this.showMessage) {
        this.showMessage(msg);
      }
    }
  
    update(resp) {
      let msg = 'Pending';
      if (this.updateMessage) {
        if(resp === 'loading') msg = 'Loading Model'
        if(resp === 'running') msg = 'Generating'
        this.updateMessage(msg);
      }
    }
  
    hide() {
      if (this.hideMessage) {
        this.hideMessage();
      }
    }
  }
  
  const progressHandler = new ProgressHandler();
  export default progressHandler;
  