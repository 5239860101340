import React, { useRef, useEffect, useContext, useState } from 'react';
import { FaPlay, FaPause } from "react-icons/fa";
import './css/TrackContainer.css';
import { OverlayContext } from '../providers/OverlayProvider';

const TrackContainer = ({ track, index = 0, app, mediaType, playingTrack, setPlayingTrack, audioRefs, singleInstance = false }) => {
  const internalAudioRefs = useRef([]);
  const audioRef = useRef(null);
  const { openOverlay } = useContext(OverlayContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [forceRenderKey, setForceRenderKey] = useState(0); // Key for forcing initial re-render

  const currentAudioRefs = audioRefs || internalAudioRefs;

  useEffect(() => {
    if (!singleInstance) {
      currentAudioRefs.current[index] = audioRef.current;
    }
  }, [index, currentAudioRefs, singleInstance]);

  useEffect(() => {
    // Force re-render on initial mount if singleInstance is true
    if (singleInstance) {
      setForceRenderKey(prevKey => prevKey + 1);
    }
  }, [singleInstance]);

  const togglePlayPause = () => {
    const audio = audioRef.current;

    if (singleInstance) {
      if (audio.paused) {
        audio.play().then(() => {
          setIsPlaying(true);
        }).catch(error => {
          console.error('Error playing audio:', error);
        });
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    } else {
      if (playingTrack !== undefined && playingTrack !== -1 && playingTrack !== index && currentAudioRefs) {
        currentAudioRefs.current[playingTrack]?.pause();
        currentAudioRefs.current[playingTrack].currentTime = 0;
      }

      if (audio.paused) {
        audio.currentTime = 0;
        audio.play().then(() => {
          if (setPlayingTrack) {
            setPlayingTrack(index);
          }
          setIsPlaying(true);
        }).catch(error => {
          console.error('Error playing audio:', error);
        });
      } else {
        audio.pause();
        if (setPlayingTrack) {
          setPlayingTrack(-1);
        }
        setIsPlaying(false);
      }
    }
  };

  const handleProgress = () => {
    const audio = audioRef.current;
    if (audio && audio.duration > 0) {
      const progress = audio.currentTime / audio.duration;
      setProgress(progress);
    }
  };

  const handleError = () => {
    console.error(`Error loading audio source for track ${index}`);
    alert(`Audio source for track ${index} is not supported or cannot be loaded.`);
  };

  const handleEnded = () => {
    const audio = audioRef.current;
    audio.currentTime = 0;
    setIsPlaying(false);
    if (!singleInstance && setPlayingTrack) {
      setPlayingTrack(-1);
    }
  };

  useEffect(() => {
    if (!singleInstance && playingTrack !== undefined && playingTrack === index) {
      setIsPlaying(true);
    } else if (!singleInstance) {
      setIsPlaying(false);
    }
  }, [playingTrack, index, singleInstance]);

  useEffect(() => {
    const progressCircle = document.getElementById(`progress-circle-${index}`);
    if (progressCircle) {
      const radius = progressCircle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      const offset = circumference - (progress * circumference);
      progressCircle.style.strokeDasharray = `${circumference} ${circumference}`;
      progressCircle.style.strokeDashoffset = `${offset}`;
    }
  }, [progress, index]);

  return (
    <div className={`trackContainer ${singleInstance ? 'singleInstance' : 'grid'}`}>
      <div className="customAudioPlayer" onClick={togglePlayPause}>
        <audio
          ref={audioRef}
          onTimeUpdate={handleProgress}
          onEnded={handleEnded}
          onError={handleError}
          controls
        >
          <source src={track.url} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
        <svg
          className={`progressCircle ${isPlaying ? 'visible' : ''}`}
          width="80"
          height="80"
          key={singleInstance ? forceRenderKey : undefined} // Force re-render on initial mount
        >
          <circle
            id={`progress-circle-${index}`}
            className="progress-circle"
            cx="40"
            cy="40"
            r="35"
            style={{
              strokeDasharray: `${219.911} ${219.911}`,
              strokeDashoffset: `${219.911 - (progress * 219.911)}`,
              transition: 'stroke-dashoffset 0.35s' // Ensure smooth transition
            }}
          />
        </svg>
        <div className="playPauseButton">
          {isPlaying ? <FaPause /> : <FaPlay />}
        </div>
      </div>
      <label>{track.prompt}</label>
      <div className="infoIconContainer" onClick={() => openOverlay(track, mediaType)}>
        <div className="infoIcon">i</div>
        <div className="tooltip">{track.prompt}</div>
      </div>
    </div>
  );
};

export default TrackContainer;
