import React, {lazy, Suspense} from 'react'; // Import Suspense and lazy
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/background.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './providers/UserProvider';
import OverlayProvider from './providers/OverlayProvider';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Helmet } from 'react-helmet';
import ProgressProvider from './providers/ProgressProvider';

const App = lazy(() => import('./HubScreens/App')); // Lazy load the App component

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  intent: "subscription",
  vault: true,
};

const Loading = () => {
  return (
    <div className="loading-container">
      <div id="preloader" style={{color:'white'}}></div>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
    {/* <!-- Google tag (gtag.js) --> */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16681102396"></script>
    <script>{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16681102396');
      `}
    </script>
      </Helmet>
    <PayPalScriptProvider options={initialOptions}>
      <div className="background-animation" />
      <Router >
        <UserProvider>
          <OverlayProvider>
            <ProgressProvider>
              <Suspense fallback={<Loading />}>
                <App />
              </Suspense>
            </ProgressProvider>
          </OverlayProvider>
        </UserProvider>
      </Router>
    </PayPalScriptProvider>
  </React.StrictMode>
);
