import React, { useState } from 'react';
import './css/SubscriptionOverlay.css';
import PayPal from './PayPal';

const cardTypes = ["Visa", "MasterCard", "American Express", "Discover"];

const SubscriptionOverlay = function () {
   

    return (
        <div className="subscribe-overlay" onClick={(e) => { e.stopPropagation(); }}>
            <h2>How would you like to pay?</h2>
            <PayPal/>
        </div>
    );
};

export default SubscriptionOverlay;




