import React, { createContext, useState, useCallback, useContext } from 'react';
import './css/MediaOverlay.css';
import NodeQuery from '../classes/nodeQuery';
import { IoClose } from "react-icons/io5";
import { FaPhotoVideo, FaRegImages, FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import { UserContext } from '../providers/UserProvider';
import TrackContainer from '../components/TrackContainer';
import SubscriptionOverlay from '../components/subscriptionOverlay';
import { useNavigate } from 'react-router-dom';

export const OverlayContext = createContext();

const OverlayProvider = ({ children }) => {
  const navigate = useNavigate();
  const [overlayActive, setOverlayActive] = useState(false);
  const [secondOverlayActive, setSecondOverlayActive] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [id, setId] = useState(null);
  const { userId } = useContext(UserContext);
  const [callbackFunc, setCallbackFunc] = useState(null);
  const [secondaryMedia, setSecondaryMedia] = useState(null);
  const [secondaryType, setSecondaryType] = useState('image');
  const [secondaryCallback, setSecondayCallback] = useState(null);


  const openOverlay = useCallback((media, type = 'image') => {
    setMediaType(type);
    setOverlayActive(true);
    if (type === 'widget') {
      setCurrentMedia(media);
    } else if (type==='rateLimit') {
      
    } 
    else {
      setCurrentMedia(media.url);
      setPrompt(media.prompt);
      setId(media.id);
    }
  }, []);

  const closeOverlay = useCallback(() => {
    setOverlayActive(false);
    setCurrentMedia(null);
    setPrompt('');
  }, []);

  const openSecondOverlay = useCallback((media, type = 'delete', callback) => {
    setSecondOverlayActive(true);
    setSecondaryType(type);
    setSecondaryMedia(media);
    setSecondayCallback(() => callback);
  }, []);

  const closeSecondOverlay = useCallback(() => {
    setSecondOverlayActive(false);
  }, []);

  const deleteMedia = useCallback(async () => {
    try {
      await NodeQuery.deleteMedia(id, mediaType, userId, currentMedia);
      if (callbackFunc) {
        callbackFunc(id);
      }
      closeOverlay();
      closeSecondOverlay();
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  }, [id, mediaType, userId, currentMedia, callbackFunc, closeOverlay, closeSecondOverlay]);

  const saveMedia = useCallback(async () => {
    try {
      if (mediaType === 'video') {
        const response = await fetch(currentMedia, {
          method: 'GET',
          mode: 'no-cors',
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = currentMedia.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        const link = document.createElement('a');
        link.href = currentMedia;
        link.download = currentMedia.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading media:', error);
    }
  }, [currentMedia, mediaType]);

  return (
    <OverlayContext.Provider value={{
      overlayActive, secondOverlayActive, currentMedia, prompt, mediaType, openOverlay, closeOverlay, openSecondOverlay, closeSecondOverlay, deleteMedia, saveMedia, setCallbackFunc
    }}>
      {children}
      {overlayActive && (
        <div className="overlay" onClick={closeOverlay}>
          <div className="overlayContent" onClick={(e) => { e.stopPropagation(); }}>
            {(mediaType === 'image' || mediaType === 'gif' || mediaType === 'sketch_image' || mediaType === 'video' || mediaType === 'music' || mediaType === 'audio' || mediaType === 'tts') && (
              <div className="overlay-media-container">
                <div className="image-actions-container">
                  {mediaType === 'video' ? (
                    <video controls className="overlay-fullImage">
                      <source src={currentMedia} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : mediaType === 'music' || mediaType === 'audio' || mediaType==='tts' ?
                    <div style={{ width: '300px', height: '250px', overflow: 'visible', padding: '10px 20px' }}>
                      <TrackContainer track={{ url: currentMedia, prompt: prompt, type:mediaType }} singleInstance={true} />
                    </div>
                    : (
                      <img src={currentMedia} className="overlay-fullImage" alt="Selected" />
                    )}
                  <div className="actions">
                    <div className="button" onClick={closeOverlay}>
                      <IoClose className='button-icon' />
                    </div>
                    <div className="button" onClick={saveMedia}>
                      <AiOutlineDownload className='button-icon' />
                    </div>
                    <div className="button" onClick={openSecondOverlay}>
                      <FaRegTrashAlt className='button-icon' />
                    </div>
                    {mediaType === 'image' && (
                      <div className="button" onClick={(e) => {navigate(`/app/image2image?imageUrl=${currentMedia}`); closeOverlay()}} title='Edit Image'>
                      <FaRegImages className='button-icon' />
                      </div>
                    )}
                    {mediaType === 'image' && (
                      <div className="button" onClick={(e) => {navigate(`/app/image2video?imageUrl=${currentMedia}`); closeOverlay()}} title='Image to Video'>
                      <FaPhotoVideo className='button-icon' />
                      </div>
                    )}
                  </div>
                </div>
                {prompt && prompt.length > 0 && mediaType !== 'music' && <div className="overlay-prompt">{prompt}</div>}
              </div>
            )}
            {mediaType === 'widget' && currentMedia}
          </div>
        </div>
      )}
      {secondOverlayActive && (
        <div className="overlay" onClick={closeSecondOverlay}>
          {secondaryType === 'subscription' ? (
              <SubscriptionOverlay />
          ) : (
            <div className="overlay-media-container" onClick={(e) => { e.stopPropagation(); }}>
              <div className="delete-confirmation">
                <p style={{ color: 'white' }}>{secondaryType==='widget' ? 'We are sad to see you go! Are you sure you want to cancel your subscription?' :'Are you sure you want to delete this media'}?</p>
                <div className='buttons-div'>
                  <button className='button' onClick={secondaryType==='widget'? ()=> secondaryCallback() : deleteMedia}>Yes</button>
                  <button className='button' onClick={closeSecondOverlay}>No</button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </OverlayContext.Provider>
  );
};

export default React.memo(OverlayProvider);
