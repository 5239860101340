import React, { createContext, useState, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NodeQuery from '../classes/nodeQuery';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generation function

// Create a Context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isPremium, setIsPremium] = useState(false);
  const [wasPremium, setWasPremium] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);

  const getOrCreateUUID = () => {
    let uuid = localStorage.getItem('uuid');
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('uuid', uuid);
    }
    return uuid;
  };

  useEffect(() => {
    // Generate or retrieve the UUID for tracking
    const refreshAuthToken = async () => {
      if (token) {
        const response = await NodeQuery.refreshToken(token);
        if (response.status === 200) {
          setToken(response.data.token);
        } else {
          setToken(null);
          setUserId(null);
          setIsPremium(false);
        }
      }
    };

    refreshAuthToken();

    const checkSubscriptionStatus = async () => {
      if (userId) {
        const response = await NodeQuery.getSubscriptionStatus(userId);
        if (response.status === 200) {
          const premiumStatus = response.data;
          // console.log('premiumStatus', premiumStatus);
          setIsPremium(premiumStatus.isPremium);
          setWasPremium(premiumStatus.wasPremium);
          setIsSubscribed(premiumStatus.isActive);
          setSubscriptionEndDate(premiumStatus.endDate);
        }
      } else {
        const uuid = getOrCreateUUID();
        NodeQuery.unAuthenticatedAccess(uuid); // Pass the UUID into unAuthenticatedAccess
      }
    };

    checkSubscriptionStatus();
  }, [userId, token]);

  // Update localStorage whenever userId or token changes
  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }
  }, [userId]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);

  return (
    <UserContext.Provider value={{ userId, setUserId, token, setToken, isPremium, setIsPremium, wasPremium, isSubscribed, setIsSubscribed, subscriptionEndDate, setSubscriptionEndDate }}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {children}
      </GoogleOAuthProvider>
    </UserContext.Provider>
  );
};

export default UserProvider;
