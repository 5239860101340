import React, { useContext, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { UserContext } from '../providers/UserProvider';
import { OverlayContext } from '../providers/OverlayProvider';
import { useNavigate } from 'react-router-dom';
import NodeQuery from '../classes/nodeQuery';
import axios from 'axios';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_PAYPAL_URL });

const PayPal = () => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = useState(options.currency);
  const [error, setError] = useState(null);
  const { userId, wasPremium, setIsPremium, setIsSubscribed } = useContext(UserContext);
  const { closeOverlay, closeSecondOverlay } = useContext(OverlayContext);
  const navigate = useNavigate();
  let subscriptionId;

  const onCurrencyChange = ({ target: { value } }) => {
    setCurrency(value);
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value,
      },
    });
  };

  const handleConversion = () => {
    // Create the script element
    const script = document.createElement('script');
    script.innerHTML = `
      gtag('event', 'conversion', {
          'send_to': 'AW-16681102396/2B5ICM_M8MwZELzYlZI-',
          'value': 1.0,
          'currency': 'USD',
          'transaction_id': ''
      });
    `;
    
    // Append the script to the document head
    document.head.appendChild(script);
  };

  const getAccessToken = async () => {
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_PAYPAL_CLIENT_SECRET;

    try {
      const response = await axiosInstance.post('/v1/oauth2/token',
        'grant_type=client_credentials',
        {
          headers: {
            'Accept': 'application/json',
            'Accept-Language': 'en_US',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          auth: {
            username: clientId,
            password: clientSecret
          },
          
        }
      );

      return response.data.access_token;
    } catch (error) {
      console.error('Error getting access token:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  const checkExistingSubscription = async (userId) => {
    try {
      const response = await NodeQuery.getSubscriptionData(userId);
      const subData = response.data;
      if (subData.length > 0) {
        const accessToken = await getAccessToken();
        const subscriptionId = subData[0].sub_id;

        const response = await axiosInstance.get(`/v1/billing/subscriptions/${subscriptionId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        return response.data;
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return null;
    }
  };

  const onCreateSubscription = async (data, actions) => {
    try {
      const planId = process.env.REACT_APP_PAYPAL_PLAN_ID;
      let startTimeISO = null;

      let subData;
      // Check for existing subscription
      const existingSubscription = await checkExistingSubscription(userId);
      if (existingSubscription) {
        subData = await NodeQuery.getSubscriptionData(userId);
        if (existingSubscription.status === 'ACTIVE') {
          // Subscription is already active
          subscriptionId = existingSubscription.id;
          setIsSubscribed(true);
          NodeQuery.reactivateSubscription(userId, existingSubscription.id, 'base');
          closeSecondOverlay();
          closeOverlay();
          setIsPremium(true);
          navigate('/refresh');
          alert(`Your subscription is already active!`);
          return;
        }
      }

      if (subData && subData.data.length > 0 && subData.data[0].end_date) {
        const endDate = new Date(subData.data[0].end_date);
        const currentDate = new Date();

        if (endDate > currentDate) {
          startTimeISO = endDate.toISOString();
        }
      }

      if (!wasPremium) {
        const trialStartTime = new Date();
        trialStartTime.setDate(trialStartTime.getDate() + 30); // Add 3 days
        startTimeISO = trialStartTime.toISOString();
      }

      // Create the subscription with PayPal
      const subscriptionResponse = await actions.subscription.create({
        plan_id: planId,
        custom_id: userId,  // Pass userId as custom_id to PayPal
        start_time: startTimeISO  // Optional: Set start time if needed
      });

      subscriptionId = subscriptionResponse.id;

      return subscriptionResponse;
    } catch (error) {
      console.error('Error creating subscription:', error);
      setError("There was an error subscribing to premium");
    }
  };

  const onApproveSubscription = async (data, actions) => {
    try {
      const details = await actions.subscription.get();
      subscriptionId = details.id;
      handleConversion()
      setIsSubscribed(true);
      setIsPremium(true);
      closeSecondOverlay();
      closeOverlay();
      navigate('/refresh');
      alert(`Successfully subscribed to premium! Thanks for your support!!!`);
    } catch (error) {
      console.error('Error approving subscription:', error);
      setError("There was an error approving the subscription");
    }
  };

  return (
    <div className="checkout">
      {isPending ? <p>LOADING...</p> : (
        <>
          <select value={currency} onChange={onCurrencyChange}>
            <option value="USD">💵 USD</option>
            <option value="EUR">💶 Euro</option>
          </select>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createSubscription={(data, actions) => onCreateSubscription(data, actions)}
            onApprove={(data, actions) => onApproveSubscription(data, actions)}
          />
          {error && <p>{error}</p>}
        </>
      )}
    </div>
  );
};

export default PayPal;
