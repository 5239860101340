import axios from './AxiosConfig';
import eventEmitter from '../classes/EventEmitter';
import { FaCommentsDollar } from 'react-icons/fa';

function wrapFunction(fn) {
  return async function(...args) {
    // Pre-function execution logic (if needed)
    try {
      const result = await fn(...args);

      return result;
    } catch (error) {
      console.error('Error executing function:', fn.name, error);
      return 0;
    }
  };
}


const NodeQuery = {
  refreshToken: wrapFunction(async function(token) {
    const response = await axios.post('/refresh-token', { token });
    return response;
  }),

  chat: wrapFunction(async function(user_id, msg, newChatRoom, chatroomID, { params }) {
    const response = await axios.post(`/chat`, {
      id: user_id,
      content: msg,
      isNew: newChatRoom,
      chatroomID: chatroomID
    });

    if (response.status === 200) {
      NodeQuery.getChatHistory(user_id, 1);
    }
    return response;
  }),

  chatInstruct: wrapFunction(async function(user_id, msg, newChatRoom, chatroomID, { params }) {
    const { instructText = null, newInstruct = null } = params;
    const response = await axios.post(`/chat-instruct`, {
      id: user_id,
      content: msg,
      isNew: newChatRoom,
      chatroomID: chatroomID,
      instructText: instructText,
      newInstruct: newInstruct
    });

    if (response.status === 200) {
      NodeQuery.getChatHistory(user_id, 1);
    }

    return response;
  }),

  getMessages: wrapFunction(async function(chatroomId, userId, lastMessageId = null) {
    const response = await axios.get(`/chat/get-messages`, {
      params: {
        chatroomId: chatroomId,
        id: userId,
        lastMessageId: lastMessageId
      }
    });
    return response.data;
  }),

  getImages: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/get-images?id=${userId}&lastId=${lastId}`);
    return response;
  }),

  getSketchImages: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/get-sketchImages?id=${userId}&lastId=${lastId}`);
    return response;
  }),

  getVideos: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/get-videos?id=${userId}&lastId=${lastId}`);
    return response;
  }),


  getChatHistory: wrapFunction(async function(userId, page = 1, isBatching = false) {
    const response = await axios.get(`/get-chatHistory`, {
      params: {
        id: userId,
        page: page
      }
    });

    if (response.status === 200 && !isBatching) {
      eventEmitter.emit('updateLinks', response.data);
    }

    return response;
  }),


  image2video: wrapFunction(async function(image, userId) {
    const response = await axios.post(`/image2video`, { image, id: userId });

    if (response.status === 200) {
      NodeQuery.getChatHistory(userId, 1);
    }

    return response;
  }),

  getImage2Vids: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/img2vid?id=${userId}&lastId=${lastId}`);
    return response;
  }),

  img2html: wrapFunction(async function(uploadedImage, userId) {
    const response = await axios.post('/image2html', { id: userId, image: uploadedImage });

    if (response.status === 200) {
      NodeQuery.getChatHistory(userId, 1);
    }

    return response;
  }),

  deleteMedia: wrapFunction(async function(id, mediaType, userId, url) {
    const response = await axios.delete(`/media`, {
      params: {
        id: id,
        user_id: userId,
        url: url,
        type: mediaType
      }
    });
    return response;
  }),

  deleteChatroom: wrapFunction(async function(userId, chatroomId, type) {
    const response = await axios.delete(`/chatroom`, {
      params: {
        id: userId,
        chatroomId: chatroomId,
        type: type
      }
    });
    return response;
  }),

  getMusic: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/get-tracks?id=${userId}&lastId=${lastId}`);
    return response;
  }),

  getAudios: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/get-audios?id=${userId}&lastId=${lastId}`);
    return response;
  }),

  getTtss: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/get-ttss?id=${userId}&lastId=${lastId}`);
    return response;
  }),

  getEssay: wrapFunction(async function(essayId, userId) {
    const response = await axios.get(`/essay`, {
      params: {
        essayId: essayId,
        id: userId
      }
    });
    return response;
  }),

  getHtml: wrapFunction(async function(htmlId, userId) {
    const response = await axios.get(`/html`, {
      params: {
        htmlId: htmlId,
        id: userId
      }
    });
    return response;
  }),

  renameChatroom: wrapFunction(async function(userId, chatroomId, newName) {
    const response = await axios.post(`/updateChatName`, {
      id: userId,
      chatroomId: chatroomId,
      newName: newName
    });
    return response;
  }),


  startSubscription: wrapFunction(async function(userId, subId, wasPremium, type) {
    const response = await axios.post(`/subscribe`, {
      id: userId,
      subId: subId,
      type: type,
      wasPremium: wasPremium
    });
    return response;
  }),

  cancelSubscription: wrapFunction(async function(userId) {
    const response = await axios.post(`/cancel-subscription`, {
        id: userId
    });
    return response;
  }),


  getSubscriptionStatus: wrapFunction(async function(userId) {
    const response = await axios.get(`/subscription-status`, {
      params: {
        id: userId
      }
    });
    if(response.status===200) return response;
    else return false
  }),

  reactivateSubscription: wrapFunction(async function(subscriptionId, userId, type) {
    FaCommentsDollar.log('reactivateSubscription', subscriptionId, userId, type);
    const response = await axios.post(`/reactivate-subscription`, {
      subId: subscriptionId,
      id: userId
    });
    return response;
  }),

  getSubscriptionData: wrapFunction(async function(userId) {
    const response = await axios.get(`/subscription-data`, {
      params: {
        id: userId
      }
    });
    return response;
  }),


  unAuthenticatedAccess: wrapFunction(async function(userId) {
    const response = await axios.post(`/unauthenticated-access`, { uuid: userId });
    return response;
  }),

  getImage2Imgs: wrapFunction(async function(userId, lastId) {
    const response = await axios.get(`/image2image?id=${userId}&lastId=${lastId}`);
    return response;
  })

};

export default NodeQuery;
